import classes from "./Introduction.module.css";
import React, { useEffect, useContext, useState } from "react";
import { images } from "../../assets/img";
import BookNowBtn from "../BookNowBtn";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";

//ABOUT US
const Introduction = (props) => {
  const { introductionRef } = props || {};
  const { isFetchActivateRemoteConfig } = useContext(AuthContext);
  const [image, setImage] = useState(images.nail_shop04);
  const isMobile = useCheckIsMobile();

  // useEffect(() => {
  //   const introduction_image = RemoteConfig.getConfigValue("introduction_image");
  //   introduction_image && setImage(introduction_image);
  // }, [isFetchActivateRemoteConfig]);

  return (
    <div ref={introductionRef} className={classes.introduction}>
      <div className={classes.introHeader}>
        <h2 className={classes.cursiveText}>{NAIL_SHOP_INFO.name}</h2>
      </div>
      <div className={classes.rowWrapper}>
        <img alt={"Intro"} src={image} className={classes.introImg} />
        <div className={classes.introTextContainer}>
          <div>
          <h1>{"EXPERIENCE BEAUTY AND TRANQUILITY!"}</h1>
            <h3 className={classes.greyIntroTextContainer}>
            Nails by Alex - Your Trusted Nail Salon in Norwich
            </h3>
          </div>
          <p className={classes.greyIntroTextContainer}>
          Discover Nails by Alex, nestled at 16 Castle Mdw, Norwich NR1 3DH. We pride ourselves on offering exceptional nail care in a warm, inviting environment. Whether you're after a simple, elegant look or a personalized, artistic design, our experienced technicians are here to bring your vision to life. At Nails by Alex, your nails are treated with precision and care every step of the way.
          </p>
          <p className={classes.greyIntroTextContainer}>
          Choose us for your next nail treatment and experience why we're becoming the top choice for nail care in Norwich. From manicures to intricate nail designs, we’re dedicated to leaving your nails both stunning and healthy after each visit.
          </p>
          <BookNowBtn />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
