import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./Policy.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import BookNowBtn from "../../components/BookNowBtn";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import { NAIL_SHOP_INFO } from "../../utils/Const";

const Policy = () => {
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Policy"}</title>
        <meta name="policy" content="Policy" />
      </Helmet>
      <Header selected={"policy"} />

      <div className={classes.policyContainer}>
        <h2 className={classes.textAlignCenter}>
          {`${NAIL_SHOP_INFO.name}\nOur policy is to protect your right and guarantee your satisfaction with the service(s) that we provided. It is not effected to your rights under Consumer Right Act 2015
To allow us to provide a quality service as efficiently as possible, the following applies:`}
        </h2>

        <h3 className={classes.titleCenterUnderline}>OUR CUSTOMER</h3>

        <ol>
          <li>Ask for the service which you would like to have.</li>
          <li>
            Inform our staff if you are allergic to any chemicals or substances
            before the receiving any service.
          </li>
          <li>
            Inform our staff if you are allergic to any chemicals or substances
            before the receiving any service.
          </li>
          <li>
            Inform our staff if you are allergic to any chemicals or substances
            before the receiving any service.
          </li>
          <li>
            Inform our staff if you are allergic to any chemicals or substances
            before the receiving any service.
          </li>
          <li>
            Children under 7 years old should be accompanied by an adult at all
            times. We will not take any responsibility if any accident happens
            to your child/children during your treatment at our salon.
          </li>
          <li>
            Children under 12 years old should be declared by adult before the
            treatment
          </li>
          <li>
            We hold no responsibility for the loss or damage of your personal
            belongings while you are having the treatment at our salon.
          </li>
          <li>Food or drink is not recommended during your treatment.</li>
          <li>Food or drink is not recommended during your treatment.</li>
          <li>
            We reserve the right to refuse service to any customer:
            <ul>
              <li type="a">
                We reserve the right to refuse service to any customer:
              </li>
              <li type="a">
                Who uses abusive language or threatening behavior.
              </li>
              <li type="a">
                Who will be a risk to themselves or cause risk to our staff
                should they use a service (in respect of the Equal Opportunities
                Act and Disability Act).
              </li>
            </ul>
          </li>
          <li>
            For appointment only:
            <ul>
              <li type="a">Customers should arrive on time.</li>
              <li type="a">
                If the customer is 10 minutes late or more, the appointment may
                have to be rescheduled.
              </li>
              <li type="a">
                Salon may be late due to service overrun; please bear this in
                mind.
              </li>
              <li type="a">
                Any cancellations should be informed 24-48 hours before the
                appointment; otherwise, there will be a charge for late
                cancellation.
              </li>
            </ul>
          </li>
        </ol>

        <br />

        <h3 className={classes.titleCenterUnderline}>{"SERVICE GUARANTEE"}</h3>
        <h3>
          {
            "Please remember, nails are jewels, not tools. Be kind to your nails and they will look beautiful for weeks after your service"
          }
        </h3>

        <ol>
          <li>Traditional polish services are not guaranteed.</li>
          <li>
            Enhancements and gel polish manicures are guaranteed for 72 hours
            after your appointment. If you lose an enhancement or notice chips
            or lifting within 72 hours, please call us to schedule a free
            repair.
          </li>
          <li>
            You should contact us to have your nails fixed within 5 days after
            your initial service.
          </li>
          <li>
            Repairs after 3 days or for breakages, tears, and corner breaks will
            be charged.
          </li>
          <li>
            We will not be responsible for any breaking or infected nails after
            you leave the salon.
          </li>
        </ol>

        <br />

        <h3 className={classes.titleCenterUnderline}>{"COMPLAINTS"}</h3>
        <h3>{"Customer must inform us by phone, text or email"}</h3>

        <ol>
          <li>
            You must arrange an appointment to have your nails fixed within 5
            days after your initial service.
          </li>
          <li>
            Repairs after 3 days or for breakages, tears, and corner breaks will
            be charged.
          </li>
          <li>
            We will not be responsible for any breaking or infected nails 72
            hours after you leave the salon due to your negligence and/or not
            following the nail after care.
          </li>
          <li>
            Any infection/breakage/allergy should be reported to the salon
            formally within 48 hours. After this time frame the salon will not
            be liable.
          </li>
        </ol>

        <br />

        <h3 className={classes.titleCenterUnderline}>{"REFUND"}</h3>
        <h3>
          {
            "No refund will be given after you have left the salon. As soon as you have walked out the salon door that means you have accepted and are happy with the service provided to your nails."
          }
        </h3>
        <ol>
          <li>
            If you are not happy with your nails, please make us aware of it
            before you pay. We will adjust them to your satisfaction or remove
            any enhancements or coatings that have been applied.
          </li>
          <li>
            No refund will be given because you have simply changed your mind.
          </li>
          <li>
            We will correct any service at no cost to you provided that you tell
            us immediately after we have performed the service that you are
            unhappy with the quality of the work. If you are still not happy
            after we fixed your nails and request the refund by the gift
            voucher, then you have to go through our Customer Complaint
            Procedure in writing to us, state the reason you are unsatisfied
            after we redid your nails and provide a photo of the evidence of the
            nails that were fixed by us.
          </li>
          <li>
            When refund gift voucher is accepted, it should be used any time
            within 6 months from the issue day
          </li>
        </ol>
      </div>

      <h1>Gift Voucher Available</h1>
      <BookNowBtn style={classes.bookNowBtnStyle} />

      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default Policy;
