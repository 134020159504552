
import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./PriceList.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import { Helmet } from "react-helmet";
import { images } from "../../assets/img";
import BookNowBtn from "../../components/BookNowBtn";
import FloatingButton from "../../components/FloatingButton/FloatingButton";

const SERVICE_LIST = {
  "Nail Extensions": [
    "Acrylic + Gel Polish",
    "Overlay + Gel Polish",
    "Colour Power",
    "French Tips",
    "Ombre",
  ],
  Hands: [
    "Gel Polish (Shellac)",
    "Gel French Tips",
    "Builder Gel (BIAB)",
    "Full Manicure",
    "Full Manicure + Gel",
  ],
  Toes: [
    "Gel Polish",
    "Gel French Tips",
    "Spa Pedicure",
    "Spa Pedicure + Shellac",
    "Toes Extension",
    "Two Big Toes Extension",
  ],
  "Take Off": ["Take Off Shellac Hands", "Take Off Acrylics"],
  Extra: ["Chrome (Metallic)", "Long Nails"],
};

const PriceList = () => {
  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Service List"}</title>
        <meta name="service-price-list" content="Service list" />
      </Helmet>
      <Header selected={"services"} />
      <div className={classes.wrapper}>
        <img src={images.pricing} alt={"pricing"} />
        <BookNowBtn />
      </div>
      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default PriceList;
