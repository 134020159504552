import React from "react";
import classes from "./Header.module.css";
import { useTranslation } from "react-i18next";
import { MENU_HEADER, NAIL_SHOP_INFO } from "../../utils/Const";
import { images } from "../../assets/img";
import { t } from "../../locales/i18n";
import BookNowBtn from "../BookNowBtn";
import { useNavigate } from "react-router-dom";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import Menu from "../Menu/Menu";
import { menuHeaderHandler } from "../../navigation/MenuHeaderHandler";

const Header = (props) => {
  const {
    introductionRef,
    contactsRef,
    serviceRef,
    onPressMainLogo,
    selected,
  } = props || {};
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  const renderLogo = () => {
    return (
      <img
        onClick={onPressMainLogo}
        className={`${classes.introLogo} ${
          onPressMainLogo ? classes.clickableLogo : null
        }`}
        alt={"main logo"}
        src={images.shop_logo}
      />
    );
  };

  return (
    <div className={classes.introHeader}>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "flex-start",
          marginTop: "2rem",
        }}
      >
        <div className={classes.headerIntroContainer}>
          <h2>{isMobile ? NAIL_SHOP_INFO.name : "Nails by\n        Alex"}</h2>
          <h3>
            {`Welcome to ${NAIL_SHOP_INFO.name}. We specialise in\ntop-quality nail care, offering a wide range of\nservices from elegant manicures to creative nail art.\n\nPop in today and let us make your nails shine!`}
          </h3>
        </div>
      </div>

      {/* {renderLogo()} */}

      {isMobile ? (
        <Menu
          selected={selected}
          introductionRef={introductionRef}
          serviceRef={serviceRef}
          contactsRef={contactsRef}
        />
      ) : (
        <div className={classes.menuItem}>
          {MENU_HEADER.map((item, index) => {
            const { name, navigationKey } = item || {};
            return (
              <h4
                key={`${name}_${index}`}
                onClick={() => menuHeaderHandler(navigationKey, navigate)}
                className={
                  selected === navigationKey ? classes.selectedText : ""
                }
              >
                {name}
              </h4>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Header;
