import { images } from "../assets/img";

export const NAIL_SHOP_INFO = {
  ggMapUrl: "https://maps.app.goo.gl/DjeeyXeGhvjvTq7C9",
  address: "16 Castle Mdw, Norwich NR1 3DH, United Kingdom",
  email: "nailsbyalex16@gmail.com",
  tel: "+441603618491",
  phone: "+44 160 361 8491",
  name: "Nails by Alex",
  bookingUrl: "https://aknailsspabooking.setmore.com/beta",
  facebookId: "nailsByAlexNorwich",
  instagramName: "alex_nailsby16",
  openingTime: {
    openTime: { weekdays: "MONDAY - SATURDAY", time: "09:30 - 18:30" },
    closeTime: { weekdays: "SUNDAY", time: "10:30 - 17:00" },
  },
};
NAIL_SHOP_INFO.instagram = `https://www.instagram.com/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.instagramChatUrl = `https://ig.me/m/${NAIL_SHOP_INFO.instagramName}`;
NAIL_SHOP_INFO.facebook = `https://www.facebook.com/${NAIL_SHOP_INFO.facebookId}`;
NAIL_SHOP_INFO.facebookMessengerUrl = `http://m.me/${NAIL_SHOP_INFO.facebookId}`;

export const SOLCO_INFO = {
  facebook: "https://www.facebook.com/solcoagency.marketing",
};

export const LANGUAGE_ID = {
  vi: "vi",
  en: "en",
};

export const CUSTOMER_FEEDBACK = [
  {
    name: "Heather Braswell",
    feedback:
      "“Beautiful salon, tons of colors and the nicest people…..and most importantly excellent work!   The shop is new but the people are very experienced and it shows.”",
    avatar: "https://i.imgur.com/g7mJPq8.png",
  },
  {
    name: "Jana Rudoll",
    feedback:
      "“Went to the salon today. Very friendly.  My nails were done to an excellent standard. I will definitely go back .  Great prices too. So worth it. One happy customer.”",
    avatar: "https://i.imgur.com/VxekuRS.png",
  },
  {
    name: "Louise Medve",
    feedback:
      "“Thank you Hanna!!! Amazing, friendly and professional service….. absolutely love my acrylic French tips on hands and toes. Beautiful clean and fresh salon with a toilet which is brilliant!! Daughter had gel french tips and loves them. The best Nail Salon in Edinburgh by far ….highly recommend a visit ❤️❤️”",
    avatar: "https://i.imgur.com/0JaNnhK.png",
  },
  {
    name: "Lucia Poulter",
    feedback:
      "“Great  salon, I’ve had a pedicure and manicure with different people and am very happy with both. Lovely people and well priced. I’ll definitely be back 👌”",
    avatar: "https://i.imgur.com/aCEUi1W.png",
  },
  {
    name: "Sarah Hickson",
    feedback:
      "“Walked in and was seen to immediately, the staff were so lovely! Had nice conversation while I got BIAB done, and can 100% say this is the best I’ve ever had my nails done in edinburgh. A lovely space, lovely people, and great results. This will definitely be my new nail spot! :)”",
    avatar: "https://i.imgur.com/F7M14q2.png",
  },
  {
    name: "Isabella White",
    feedback:
      "“I booked a last-minute appointment, and they exceeded my expectations. My gel manicure has been flawless for weeks!”",
    avatar: "https://i.imgur.com/KxhUFKM.jpg",
  },
  {
    name: "Scarlett Brown",
    feedback:
      "“The calm and relaxing environment makes it my absolute go-to spot for all my nail care needs. Not only do they provide top-notch services, but they also take the time to offer personalized tips for maintaining healthy nails between appointments, which have been incredibly helpful. I always leave feeling refreshed, with my nails looking flawless”",
    avatar: "https://i.imgur.com/kI3rsKc.jpg",
  },
  {
    name: "Ella Wilson",
    feedback:
      "“I treated my mom to a birthday pampering day, and it was absolutely perfect. The staff made us feel so special, paying close attention to every detail. My mom loved the personalized care, and the manicures were flawless. It was a wonderful experience, and we left feeling pampered and happy.”",
    avatar: "https://i.imgur.com/92gXkP8.jpg",
  },
];

export const MENU_HEADER = [
  { name: "Home", navigationKey: "home" },
  { name: "About Us", navigationKey: "about-us" },
  { name: "Services", navigationKey: "services" },
  { name: "Booking", navigationKey: NAIL_SHOP_INFO.bookingUrl },
  // { name: "Contacts", navigationKey: "contactsRef" },
  { name: "Contact", navigationKey: "contact-us" },
  { name: "Policy", navigationKey: "policy" },
];
