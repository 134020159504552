import React, { useContext, useEffect, useRef, useState } from "react";
import classes from "./AboutUs.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { t } from "../../locales/i18n";
import Header from "../../components/Header/Header";
import { Helmet } from "react-helmet";
import BookNowBtn from "../../components/BookNowBtn";
import FooterInfo from "../../components/FooterInfo/FooterInfo";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import { ReactPhotoCollage } from "react-photo-collage";
import { images } from "../../assets/img";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import { NAIL_SHOP_INFO } from "../../utils/Const";

const AboutUs = () => {
  const navigate = useNavigate();
  const isMobile = useCheckIsMobile();

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"About us"}</title>
        <meta name="about-us" content="About us" />
      </Helmet>
      <Header selected={"about-us"} />

      <div className={classes.introTextContainer}>
        <div>

          <h1>{"WHERE BEAUTY MEET RELAXATION!"}</h1>
          <h3 className={classes.greyIntroTextContainer}>
            {`${NAIL_SHOP_INFO.name} - Premier Nail Salon in Norwich`}
          </h3>
        </div>
        <p className={classes.greyIntroTextContainer}>
          {`Step into ${NAIL_SHOP_INFO.name}, your new go-to destination at 35 Elm Row, Edinburgh. Here, we combine expert craftsmanship with a welcoming atmosphere to deliver nail services that stand out. Our passionate team is dedicated to bringing your nail vision to life, whether you're drawn to timeless styles like a classic manicure or seeking unique, customized nail art. At ${NAIL_SHOP_INFO.name}, your hands and feet are in the best care.`}
        </p>
        <p className={classes.greyIntroTextContainer}>
          {`Trust us with your next nail experience, and discover why ${NAIL_SHOP_INFO.name} is quickly becoming a top choice in Edinburgh. Our comprehensive range of services is designed to leave your nails looking flawless and feeling healthy every time you visit.`}
        </p>
      </div>

      <ReactPhotoCollage
        width={"100%"}
        height={
          isMobile
            ? ["30rem", "23rem", "35rem", "28rem","29rem","31rem","37rem"]
            : ["49rem", "38rem", "35rem","47rem",  "37rem"]
        }
        layout={isMobile ? [2, 1, 2, 3,2,3,1] : [3, 2, 4,3,2]}
        photos={[
          { source: images.nail_shop04 },
          { source: images.nail_shop02 },
          { source: images.nail1 },
          { source: images.nail_shop05 },
          { source: images.nail2 },
          { source: images.nail_shop01 },
          { source: images.nail6 },
          { source: images.nail_shop06 },
          { source: images.nail_shop07 },
          { source: images.nail_shop08 },
          { source: images.nail3 },
          { source: images.nail4 },
          { source: images.nail_shop03 },
          { source: images.nail_shop09 },
        ]}
        showNumOfRemainingPhotos
      />
      <h1>Gift Voucher Available</h1>
      <BookNowBtn style={classes.bookNowBtnStyle} />

      <FooterInfo style={classes.footerStyle} />
      <FloatingButton />
    </div>
  );
};

export default AboutUs;
