import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import Helpers from "../../utils/Helpers";
import classes from "./InstagramFeed.module.css";
import { images } from "../../assets/img";
import { NAIL_SHOP_INFO } from "../../utils/Const";
import useCheckIsMobile from "../../hook/useCheckIsMobile";
import RemoteConfig from "../../services/RemoteConfig";
import AuthContext from "../../store/auth-context";
import '../../index.css'
import FadeIn from "../FadeIn/FadeIn";
import Carousel from "../Carousel/Carousel";


const INSTAGRAM_IMAGES = [
  { name: "fourth item", src: images.nail4 },
  { name: "first item", src: images.nail1 },
  { name: "fifth item", src: images.nail5 },
  { name: "third item", src: images.nail_shop05 },
  { name: "tenth item", src: images.nail10 },
  { name: "second item", src: images.nail2 },
  { name: "third item", src: images.nail_shop04 },
  { name: "ninth item", src: images.nail9 },
  { name: "eighth item", src: images.nail8 },
  { name: "third item", src: images.nail_shop06 },
  { name: "eleventh item", src: images.nail11 },
  { name: "seventh item", src: images.nail7 },
  { name: "sixth item", src: images.nail6 },
  { name: "third item", src: images.nail3 },
];


const InstagramFeed = () => {

  const openInstagram = () => {
    window.open(NAIL_SHOP_INFO.instagram, "_blank");
  };

  return (
    <div className={classes.container}>
      <div className={classes.instaIconContainer} onClick={openInstagram}>
        <img
          src={images.instaIcon}
          alt={"instagram-icon"}
          className={classes.instaIcon}
        />
        <h3>{NAIL_SHOP_INFO.instagramName}</h3>
      </div>

      
      <FadeIn>
        <Carousel
          interval={7000}
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
          renderIndicator={() => null}
        >
          {Helpers.isArrayAvailable(INSTAGRAM_IMAGES)
            ? INSTAGRAM_IMAGES.map((item, index) => {
                const { name, src } = item || [];
                return (
                  <div onClick={openInstagram} key={`${name + index}`} className={classes.carouselItem}>
                    <img alt={name} src={src} />
                  </div>
                );
              })
            : null}
        </Carousel>
      </FadeIn>
      

      <div onClick={openInstagram} className={`${classes.followMeContainer} linear-gradient-bg`}>
        <img
          alt={"instagram-icon"}
          src={images.instaIcon}
          className={`${classes.instaIconSmall}`}
        />
        <h4>Follow us on Instagram</h4>
      </div>
    </div>
  );
};

export default memo(InstagramFeed);
